// Core packages
import Lottie from "lottie-react";

// Animation files
import loadingAnimation from "../assets/animations/loading_animation.json";

export const LoadingAnimation = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flex: "1",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        style={{height: "88px", width: "88px"}}
        animationData={loadingAnimation}
      />
    </div>
  );
};
