import axios from "axios";
import Cookies from "js-cookie";

import { API_URL } from "./config";


export const createAxiosInstance = (user) => {
    // TODO maybe add some logic around re-authenticating if necessary?
    const config = { baseURL: API_URL };
    if (!!user) {
        const accessToken = user.access;
        config["headers"] = {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': `Bearer ${accessToken}`
        }
    }
    return axios.create(config);
}

export const createQuerystring = (queryparams) => {
    if (Object.keys(queryparams) === 0) return "";
    let querystring = "?";
    for (const [key, value] of Object.entries(queryparams)) {
        if (querystring !== "?") {querystring += "&"}
        querystring += `${key}=${value}`
    }
    return querystring;
}


// NOTE stuff below this line will most likely be deprecated
const formatUrl = (endpoint) => {
    const slash = endpoint.endsWith("/") ? "" : "/";
    return API_URL + endpoint + slash;
}

export function httpGet(endpoint, processData) {
    axios
    .get(formatUrl(endpoint))
    .then(response => processData(response))
    .catch((error) => {
        console.log(error);
    });
};

export function httpPost(data, endpoint, processData) {
    // Django requires CSRF token, otherwise you get a 403 Network Error
    // (it's a security measure)
    const csrfToken = Cookies.get('csrftoken');

    axios
    .post(
        formatUrl(endpoint),
        data,
        {headers: { 'X-CSRFToken': csrfToken }}
    )
    .then(response => processData(response))
    .catch((error) => {
        console.log(error);
    });
}

export function httpDelete(id, endpoint, processData) {
    axios
    .delete(formatUrl(endpoint))
    .then(response => processData(response))
    .catch((error) => {
        console.log(error);
    });
}
