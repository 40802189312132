import { useEffect } from "react";
import { useSelector } from "react-redux";

import { createAxiosInstance } from "../requests/general";
import { selectAuth } from "../redux/slices/authSlice";


export default function EditActivityPage() {
    const user = useSelector(selectAuth);

    /*
    useEffect(() => {
        const instance = createAxiosInstance(user);
        // this is taken from the example requests
        const requestBody = {
            "node_type": "unit",
            "parent_id": 1,
            "nodes": [
                {
                    "order": 1.5,
                    "translation_sets": {
                        "name_translation_set": {
                            "eng|en-US|1": {
                                "text": "my mom's donut initialization unit"
                            },
                            "zho|zh-TW|1": {
                                "text": "我媽的甜卷初始化單位"
                            }
                        }
                    },
                    "foreign_keys": {},
                    "values": {
                        "ready_for_publishing": false
                    }
                }
            ]
        };
        instance.post("/node", requestBody)
        .then(response => {
            console.log(response);
            console.log("Saved!");
        })
        .catch(error => {
            // TODO do something 
            console.log(error);
        })
    });
    */

    return (
        <>
            <h1>Edit Activity</h1>
        </>
    )
}