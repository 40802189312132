import axios from "axios";
import Cookies from "js-cookie";

import {authSlice} from "../slices/authSlice";
import {API_URL, JWT_OBTAIN_ENDPOINT} from "../../requests/config";

const {loginSuccess, loginFailure} = authSlice.actions;
export const fetchJWTs = (email, password) => {
  return async (dispatch) => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await axios.post(
        API_URL + JWT_OBTAIN_ENDPOINT,
        {
          email,
          password,
        },
        {headers: {"X-CSRFToken": csrfToken}}
      );
      const loginData = {
        // TODO is it a security issue to keep the role in the frontend state?
        ...response.data,
        email: email,
      };
      dispatch(loginSuccess(loginData));
      return true;
    } catch (error) {
      dispatch(loginFailure(error));
      return false;
    }
  };
};
