// Core packages
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// Redux
import {selectAuth} from "../redux/slices/authSlice";
import {selectTaxonomy} from "../redux/slices/taxonomySlice";
import {taxonomySlice} from "../redux/slices/taxonomySlice";

// Requests
import {createAxiosInstance} from "../requests/general";
import {findString} from "../utils/transformData";

// Stylesheets
import styles from "../styles/pages/layouts.module.css";

// Animations
import {LoadingAnimation} from "../components/animations";

// Component logic
export default function LayoutsPage() {
  // persisted state
  const persistedTaxonomy = useSelector(selectTaxonomy);
  const user = useSelector(selectAuth);
  const dispatch = useDispatch();
  const {taxonomyFetchSuccess} = taxonomySlice.actions;

  // local state
  const [taxonomy, setTaxonomy] = useState(persistedTaxonomy.taxonomy);

  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  const {lessonId} = location.state;

  // callback
  const activityOrder = 100; // TODO (?)
  const createActivity = (activityLayout) =>
    navigate("/new-activity", {
      state: {activityLayout, lessonId, activityOrder},
    });

  // TODO get the interface language from somewhere
  const interfaceLanguage = "eng";

  useEffect(() => {
    // avoid re-calling the API if we already have the taxonomy
    if (Object.keys(persistedTaxonomy.taxonomy).length > 0) {
      console.log("persisted");
      setTaxonomy(persistedTaxonomy.taxonomy);
    } else {
      // call the API
      console.log("calling the API");
      const instance = createAxiosInstance(user);
      instance
        .get("/taxonomy")
        .then((response) => {
          const parsedData = JSON.parse(response.data);
          console.log(parsedData);
          setTaxonomy(parsedData);
          dispatch(taxonomyFetchSuccess(parsedData));
        })
        .catch((error) => {
          // TODO do something meaningful here
          console.log(error);
        });
    }
  }, []);

  console.log(taxonomy);

  // If taxonomy hasn't loaded yet, render the animation widget
  if (taxonomy.length === 0) {
    return <></>;
  }

  // If the taxonomy has loaded, render all content
  if (taxonomy.length > 0) {
    return (
      <main className={styles["container"]}>
        {/*  */}

        {/* HEADER CONTAINER */}
        <div className={styles["header-container"]}>
          <h1>Select Layout</h1>
        </div>
        {/*  */}

        {/* FILTER CONTAINER */}
        <div className={styles["filter-container"]}></div>
        {/*  */}

        {/* LAYOUTS CONTAINER */}
        <div className={styles["layouts-container"]}>
          {taxonomy.map((activityClass, i) => {
            return (
              <LayoutSelectionCard
                onClick={createActivity}
                activityClass={activityClass}
                interfaceLanguage={interfaceLanguage}
              />
            );
          })}
        </div>
        {/*  */}

        {/*  */}
      </main>
    );
  }
}

// Sticking this here for now

const LayoutSelectionCard = ({onClick, activityClass, interfaceLanguage}) => {
  const layout =
    activityClass.children.length > 0 ? activityClass.children[0] : null;

  return (
    <div className={styles["card-container"]}>
      <h3>{findString(activityClass.name, interfaceLanguage)}</h3>
      <div>
        <p>Description here...</p>
      </div>
      <div className={styles["card-cta"]}>
        <button
          className={styles["create-button"]}
          onClick={() => {
            onClick(layout);
          }}
        >
          create →
        </button>
      </div>
    </div>
  );
};
