// Core packages
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";

// Styles
import "./index.css";

// App
import App from "./app";

// Redux store
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

// Create and render the React app
// TODO make a loading component
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
