// Core packages
import {configureStore} from "@reduxjs/toolkit";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Slices
import {authSlice} from "./slices/authSlice";
import {taxonomySlice} from "./slices/taxonomySlice";

const authPersistConfig = {
  // it seems that if you don't have distinct keys, then calling up the
  // persisted value of one slice will also call up the persisted value of another
  key: "auth",
  storage,
  whitelist: ["user"],
};
const taxonomyPersistConfig = {
  key: "taxonomy",
  storage,
};

const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice.reducer
);
const persistedTaxonomyReducer = persistReducer(
  taxonomyPersistConfig,
  taxonomySlice.reducer
);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    taxonomy: persistedTaxonomyReducer,
  },
});
const persistor = persistStore(store);
export {store, persistor};
