// Core packages
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

// Redux
import {useSelector} from "react-redux";
import {selectAuth} from "../redux/slices/authSlice";

// Requests
import {createAxiosInstance, createQuerystring} from "../requests/general";

// Stylesheets
import styles from "../styles/pages/editTree.module.css";

// Animations
import {LoadingAnimation} from "../components/animations";

const PrepareDisplayTreeData = (tree, accumulator) => {
  // empty of data for now, just get all the children
  tree.forEach((subTree, index) => {
    // activities don't contain children, for the purposes of this display
    let children;
    if (subTree.node_type === "activity") {
      children = [];
    } else {
      children = PrepareDisplayTreeData(subTree.children, []);
    }

    const node = {
      nodeType: subTree.node_type,
      nodeId: subTree.values.id,
      nodeIndex: index, // can probably remove
      children: children,
    };
    accumulator.push(node);
  });
  return accumulator;
};

// TODO general enough to move to components/ ?
const Concertina = ({displayTreeData}) => {
  const navigate = useNavigate();
  const doNothing = () => {
    /* do nothing */
  };
  const chooseLayout = (lessonId) => navigate("/layouts", {state: {lessonId}});

  return (
    <div className={styles["node-container"]}>
      {displayTreeData.map((node, index) => {
        return (
          <div
            className={
              ["unit", "course"].includes(node.nodeType)
                ? styles["node-inner-container-no-padding"]
                : styles["node-inner-container"]
            }
            key={index}
            onClick={
              node.nodeType === "lesson"
                ? () => chooseLayout(node.nodeId)
                : doNothing
            }
          >
            {node.nodeType !== "course" && (
              <div className={styles["node"]}>
                {node.nodeType} {node.nodeId}
              </div>
            )}
            {node.children.length > 0 && (
              <Concertina displayTreeData={node.children} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default function EditTreePage() {
  const user = useSelector(selectAuth);
  const location = useLocation();
  const {courseId} = location.state;
  const [tree, setTree] = useState([]);

  useEffect(() => {
    const instance = createAxiosInstance(user);
    const qs = createQuerystring({
      node_type: "course",
      id: courseId,
    });
    console.log("/tree" + qs);
    instance
      .get("/tree" + qs)
      .then((response) => {
        // console.log(response.data);
        const parsedData = JSON.parse(response.data);
        setTree(parsedData); // TODO need to persist?
      })
      .catch((error) => {
        // TODO do something meaningful here
        console.log(error);
      });
  }, []);

  const displayTreeData = PrepareDisplayTreeData(tree, []);
  console.log(displayTreeData);

  return (
    <main className={styles.container}>
      {/*  */}

      {/* HEADER ACTIONS CONTAINER */}
      <div className={styles["header-actions-container"]}>
        <h1>Course Name</h1>
        <div className={styles["header-actions"]}>
          <button className={styles["dropdown-primary"]}>draft</button>
          <button className={styles["button-primary"]}>save course</button>
        </div>
      </div>
      {/*  */}

      {/* COURSE TREE CONTAINER */}
      <div className={styles["course-tree-container"]}>
        {displayTreeData.length === 0 ? (
          <LoadingAnimation />
        ) : (
          <Concertina displayTreeData={displayTreeData} />
        )}
      </div>
      {/*  */}

      {/*  */}
    </main>
  );
}
