// Core packages
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

// Redux
import {selectAuth} from "../redux/slices/authSlice";

// Requests
import {createAxiosInstance} from "../requests/general";

// Utils
import {findString} from "../utils/transformData";

// Stylesheets
import styles from "../styles/pages/newActivity.module.css";

const writeTranslationSetRequests = () => {
  // TODO
  const tset = {
    name_translation_set: {
      "eng|en-US|1": {
        text: "my mom's donut initialization unit",
      },
      "zho|zh-TW|1": {
        text: "我媽的甜卷初始化單位",
      },
    },
  };
  return tset;
};

const writeNodePostRequestBody = () => {
  const requestBody = {
    node_type: "activity",
    parent_id: 1, // TODO need the lesson ID for this
    nodes: [
      {
        order: 1.5, // TODO need to set this
        translation_sets: writeTranslationSetRequests(),
        foreign_keys: {}, // any TODOs here?
        values: {
          ready_for_publishing: false, // just assume for now
        },
      },
    ],
  };
  return requestBody;
};

export default function CreateActivityPage() {
  const user = useSelector(selectAuth);
  const location = useLocation();
  const {activityLayout, lesson, activityOrder} = location.state;

  const submitActivity = (e) => {
    e.preventDefault();
    console.log("activity submitted (sort of)");
  };

  const _submitActivity = () => {
    const instance = createAxiosInstance(user);
    // this is taken from the example requests
    const requestBody = writeNodePostRequestBody();
    instance
      .post("/node", requestBody)
      .then((response) => {
        console.log(response);
        console.log("Saved!");
      })
      .catch((error) => {
        // TODO do something
        console.log(error);
      });
  };

  console.log(activityLayout);
  console.log(activityLayout.children);

  return (
    <main className={styles["container"]}>
      {/*  */}

      {/* HEADER */}
      <div>
        <h1>Activity Name/ID Here</h1>
        <p>{findString(activityLayout.name)}</p>
      </div>
      {/*  */}

      <div className={styles["inner-container"]}>
        {/* ACTIVITY FORM */}
        <form className={styles["form-container"]} onSubmit={submitActivity}>
          {activityLayout.children.map((elementRole, index) => {
            return (
              <div key={index}>
                <label htmlFor={elementRole.id}>
                  {findString(elementRole.name)} ({elementRole.element_type})
                </label>
                <input type="text" name={elementRole.id} />
              </div>
            );
          })}
          <button type="submit">Submit</button>
        </form>
        {/*  */}

        {/* DEVICE PREVIEW */}
        <div className={styles["device-preview-container"]}></div>
        {/*  */}
      </div>

      {/*  */}
    </main>
  );
}
