// Core packages
import {useDispatch} from "react-redux";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

// Redux actions
import {fetchJWTs} from "../redux/calls/auth";

// Stylesheet
import styles from "../styles/pages/login.module.css";

// Translation
import {useTranslation} from "react-i18next";

// Assets
import Image from "../assets/images/labs_background_login.png";

export default function LoginPage() {
  // TODO: could use refs instead of state for the form
  const [formData, setFormData] = useState({email: "", password: ""});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation("loginPage");

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    // TODO This is breaking the rules of Redux
    // dispatch() should not contain any async code or cause side effects
    const success = await dispatch(
      fetchJWTs(formData["email"], formData["password"])
    );
    if (success) {
      navigate("/courses");
    }
  };

  return (
    <main className={styles["container"]}>
      <div className={styles["content-container-wrapper"]}>
        <div className={styles["content-container"]}>
          {/*  */}

          {/* Header */}
          <div className={styles["header-container"]}>
            <h1>{t("welcome")}</h1>
            <p>{t("pleaseSignIn")}</p>
          </div>
          {/*  */}

          <div className={styles["inputs-container"]}>
            {/* Email Input */}
            <input
              className={styles["login-form-input"]}
              name="email"
              type="email"
              autoFocus
              autoComplete="off"
              placeholder="example@example.com"
              onChange={handleInputChange}
              value={formData["email"]}
            />
            {/*  */}

            {/* Password Input */}
            <input
              className={styles["login-form-input"]}
              name="password"
              type="password"
              autoComplete="off"
              placeholder={t("passwordPlaceholder")}
              onChange={handleInputChange}
              value={formData["password"]}
            />
            {/*  */}

            {/* Login button */}
            <button
              onClick={handleLogin}
              className={styles["login-form-button"]}
            >
              {t("loginButton")}
            </button>
            {/*  */}

            {/*  */}
          </div>
        </div>
      </div>
      {/*  */}

      {/* FEATURE IMAGE */}
      <div
        className="feature-img-container"
        style={{
          backgroundImage: `url(${Image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      {/*  */}

      {/*  */}
    </main>
  );
}
