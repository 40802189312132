// Core packages
import {useEffect} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import i18n from "i18next";
import {useTranslation, initReactI18next} from "react-i18next";

// Styles
import "./app.css";

// Pages
import LoginPage from "./pages/login";
import CoursesPage from "./pages/courses";
import CreateActivityPage from "./pages/newActivity";
import EditActivityPage from "./pages/editActivity";
import EditTreePage from "./pages/editTree";
import LayoutsPage from "./pages/layouts";

// Components
import Navbar from "./components/navbar";

// Translations
const translations = require("./assets/translations.json");
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(translations);

// App logic
export default function App() {
  const {i18n} = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <BrowserRouter>
      <div className="router-outer-wrapper">
        <Navbar />
        <div className="router-inner-wrapper">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/courses" element={<CoursesPage />} />
            <Route path="/edit-activity" element={<EditActivityPage />} />
            <Route path="/edit-tree" element={<EditTreePage />} />
            <Route path="/layouts" element={<LayoutsPage />} />
            <Route path="/new-activity" element={<CreateActivityPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}
