import {createSlice} from "@reduxjs/toolkit";

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState: {taxonomy: []},
  reducers: {
    taxonomyFetchSuccess: (state, action) => {
      state.taxonomy = action.payload;
    },
  },
});

export const selectTaxonomy = (state) => state.taxonomy;
