export const getBrowserLanguage = () => {
    const mapper = {
        // listing out all the non-English language codes
        // and how they map onto our language codes
        "zh-": "zho",
    }
    const code = navigator.language.substring(0, 3);
    const language = mapper[code];
    if (language !== undefined) return language;
    return "eng"; // default
}