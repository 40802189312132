// TODO different URL for dev and prod
// export const API_URL = "http://127.0.0.1:8000/api/"
export const API_URL =
  "https://panglot-backend-b7fc0db7417b.herokuapp.com/api/";

export const JWT_OBTAIN_ENDPOINT = "token/obtain";
export const JWT_REFRESH_ENDPOINT = "token/refresh";
export const NODE_ENDPOINT = "node";
export const TAXONOMY_ENDPOINT = "taxonomy";
export const TREE_ENDPOINT = "tree";
