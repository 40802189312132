// Core packages
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

// Redux
import {createAxiosInstance} from "../requests/general";
import {selectAuth} from "../redux/slices/authSlice";

// Stylesheets
import styles from "../styles/pages/courses.module.css";

// Translation
import {useTranslation} from "react-i18next";
import {getBrowserLanguage} from "../utils/browser";

// Assets
import {LoadingAnimation} from "../components/animations";

// Component logic
export default function CoursesPage() {
  const user = useSelector(selectAuth);
  const {t} = useTranslation("coursePage");
  const navigate = useNavigate();

  // Hard-coded table headers
  const tableHeaders = [
    "courseNameTableHeader",
    "languageTableHeader",
    "courseStatusTableHeader",
  ];

  // TODO maybe can use browser language for this?
  const [interfaceLanguage, setInterfaceLanguage] = useState({
    language: getBrowserLanguage(),
    dialect: null,
  });
  const [courses, setCourses] = useState([]);

  const navigateToCourse = (courseId) => {
    navigate("/edit-tree", {state: {courseId: courseId}});
  };

  const chooseText = (nameData, language, dialect) => {
    const dunnoText = "???";
    const nullDialect = "<none>";
    if (nameData.length === 0) return dunnoText;

    const options = nameData[language];
    if (options === undefined) {
      // language is not present - default to English with null dialect
      if (language === "eng") return dunnoText;
      return chooseText(nameData, "eng", nullDialect, dunnoText);
    } else {
      // try to find something in the right dialect
      if (dialect === null) dialect = nullDialect;
      let defaultText = options[0]["text"]; // arbitrary dialect
      for (let i = 0; i < options.length; i++) {
        const thisDialect = options[i]["dialect"];
        const thisText = options[i]["text"];
        if (dialect === thisDialect) return thisText;
        if (thisDialect === nullDialect) defaultText = thisText;
      }
      // dialect not found; try default
      if (defaultText !== undefined) return defaultText;
      return dunnoText;
    }
  };

  useEffect(() => {
    const instance = createAxiosInstance(user);
    instance
      .get("/courses")
      .then((response) => {
        const responseData = response.data;
        const courses = [];
        for (let i = 0; i < responseData.length; i++) {
          const data = responseData[i];
          const course = {id: data.id};
          // TODO updatedAt is not currently reflecting when anything in the course was updated
          //course["updatedAt"] = data["updated_at"];
          course["status"] = {
            false: t("draft"),
            true: t("published"),
          }[data["ready_for_publishing"]];
          course["name"] = chooseText(
            data["name"],
            interfaceLanguage["language"],
            interfaceLanguage["dialect"]
          );
          course["language"] = chooseText(
            data["language"],
            interfaceLanguage["language"],
            interfaceLanguage["dialect"]
          );
          courses.push(course);
        }
        console.log(courses);
        setCourses(courses);
      })
      .catch((error) => {
        // TODO do something meaningful here
        console.log(error);
      });
  }, []);

  if (courses.length === 0) {
    return (
      <>
        <LoadingAnimation />
      </>
    );
  }

  return (
    <main className={styles["container"]}>
      {/*  */}

      {/* HEADER */}
      <h1>{t("coursesTitle")}</h1>
      {/*  */}

      {/* SEARCH WIDGET */}
      <input
        className={styles["search-widget-input"]}
        type="text"
        placeholder={t("searchPlaceholder")}
      />
      {/*  */}

      {/* COURSES DISPLAY TABLE */}
      <section className={styles["courses-display-container"]}>
        <table>
          <thead>
            <tr>
              {tableHeaders.map((text, i) => {
                return <th key={i}>{t(text)}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {courses.map((c, i) => {
              return (
                <tr onClick={() => navigateToCourse(c.id)} key={i}>
                  <td>{c.name}</td>
                  <td>{c.language}</td>
                  <td>{c.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      {/*  */}

      {/*  */}
    </main>
  );
}
