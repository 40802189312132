// Core packages
import {useNavigate, useLocation} from "react-router-dom";

// Stylesheets
import styles from "../styles/components/navbar.module.css";

// Component logic
export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToCourses = (location) => {
    if (location.pathname === "/") {
    }
    if (location.pathname === "/courses") {
    }
    if (location.pathname === "/edit-tree") {
      navigate("/courses");
    }
    if (location.pathname === "/layouts") {
      navigate("/edit-tree");
    }
    if (location.pathname === "/new-activity") {
      navigate("/layouts");
    }
  };

  if (location.pathname === "/") {
    return <nav></nav>;
  }

  return (
    <nav className={styles["nav-container"]}>
      {location.pathname !== "/courses" && (
        <p
          onClick={() => {
            navigateToCourses(location);
          }}
          className={styles.backlink}
        >
          ← Back
        </p>
      )}
      <div></div>
    </nav>
  );
}
